/**
* @fileoverview Chat, componente donde se encuentra el codigo relacionada al chatweb.
* @version  2.0Beta
* @author Hugo Chicoj<hugocc@automtadg.com>
* @copyright  Automtadg.com
*
* History
* v2.0Beta – Se implemento la libreria react-chat-widget <https://github.com/Wolox/react-chat-widget> para la interfaz del chat
*
* v1.0Beta - Fue desarrollada por Dina Antuche <madian@automtadg.com>
*/

import React,{useEffect} from 'react';
import {Widget,
        addResponseMessage,
        addUserMessage,
        renderCustomComponent,
        dropMessages,
        markAllAsRead,
        toggleInputDisabled,
        toggleMsgLoader,
        addLinkSnippet,
        setQuickButtons,
      } from 'react-chat-widget';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { db,auth } from "../services/firebase";
import Login from "./Login";
import './styles.css'
import 'react-chat-widget/lib/styles.css';


const ex_imagen=['jpg','jpeg','png'];

/**
* Componente para un mensaje tipo imagen
* @param {string} src - url de la imagen para mostrar en el chat
*/
function Image(props) {
    return(<img alt="placeholder" width={'250px'} src={props.src}></img>)
}


function Texto(props){
  return(<Typography variant="body2" component="p">
            {props.contenido}
        </Typography>
      );
}

/**
* Componente donde esta el chatweb y los metodos para recibir y enviar mensajes
* @param {string}  src- url de la imagen para mostrar en el chat
* @param {string} automataBot - El id del bot de automata
* @param {string} automataEmpresa - El id de la empresa de automata
* @param {string} automataAvatar - La url del avatar que aparecera en el chat
*/
function Chat(props){


  const [nombreBot,setNombreBot] = React.useState('');
  const [usuario,setUsuario] = React.useState("");
  const [resultado,setResultado] = React.useState("");

  const [colores, setColores] = React.useState({fondo: '#1136b3', texto: 'white'});

/**

*/
   useEffect(() =>{



     toggleMsgLoader();
     var inicio=true;
     auth.onAuthStateChanged(user => {
       setQuickButtons([]);
        if (user) {

            if(user.displayName !== null || user.displayName !== undefined)
            {
              db.ref('cliente_chat/'+props.automataEmpresa+'/webchat/'+props.automataBot+"/"+user.uid+"/atributos/wb_username").set(user.displayName);
            }

            if(inicio===false){toggleMsgLoader();toggleInputDisabled()}else{inicio=false}
            setUsuario(user);
            db.ref('bots/'+props.automataEmpresa+'/'+props.automataBot+'/nombre').once('value').then(snapshot => {
                    var datos=snapshot.val();
                    setNombreBot(datos);
            });
            ObtenerChats(user);

        } else {
            if(inicio===false){toggleMsgLoader()}else{inicio=false}
            toggleInputDisabled()
            setNombreBot(props.automataNameChat);
            setUsuario("");
            dropMessages();
            addResponseMessage("Para continuar al chat debes iniciar sesión con una de las siguientes opciones");
            renderCustomComponent(Login, {bot:props.automataEmpresa, id:props.automataBot})
            toggleMsgLoader();
        }
      });

      return ()=>{
          setNombreBot("");
          setUsuario("");
      }
   },[]);


  const ObtenerChats=(usuario)=>{

     dropMessages();

       addResponseMessage("cargando datos...");
     db.ref('/conversaciones/'+props.automataEmpresa+'/webchat/'+props.automataBot+'/'+usuario.uid).orderByKey().limitToLast(40).once('value').then(function(snapshot){

        let ultimo="";

         if(snapshot.val()!==null)
         {
             snapshot.forEach((item, i) => {

               analizarMensaje(item.val(),true);
               if(item.val().hasOwnProperty('menu'))
               {
                 ultimo={
                   key:item.key,
                   menu:item.val().menu
                 }
              }else{
                ultimo={
                  key:item.key,
                  menu:null,
                }
              }
             });
         }

         return ultimo;
     }).then(function(ultimo){
       markAllAsRead();
       toggleMsgLoader();
       if(ultimo==="")
       {
           sendMessage(usuario.uid,Date.now(),"inicio");

           db.ref('/conversaciones/'+props.automataEmpresa+'/webchat/'+props.automataBot+'/'+usuario.uid).on('child_added',function(snap){

             if (snap!==null){
                analizarMensaje(snap.val(),false);
             }
           });
       }else{

         //si el ultimo tiene menu
         if(ultimo.menu!==null)
         {
           let opciones=[]
           ultimo.menu.forEach((item, i) => {
             opciones.push({
               label: item,
               value: item})
           });
           setQuickButtons(opciones)
         }

         db.ref('/conversaciones/'+props.automataEmpresa+'/webchat/'+props.automataBot+'/'+usuario.uid).orderByKey().startAfter(ultimo.key).on('child_added',function(snap){

           if (snap!==null){
              analizarMensaje(snap.val(),false);
           }
         });
       }

     });

  }

  function analizarMenu(item)
  {
    //si el ultimo tiene menu
    if(item.hasOwnProperty('menu'))
    {
      let opciones=[]
      item.menu.forEach((item, i) => {
        opciones.push({
          label: item,
          value: item})
      });
      setQuickButtons(opciones)
    }
  }

  function analizarMensaje(item ,usuario){

    if(item === null )
    {
      return 0;
    }

    let contenido=item.contenido.trim();
    let url_contenido=isUrl(contenido);


    //const regex = /\n/ig;
    //contenido=contenido.replace(regex, '<br/>');

    if(url_contenido.url && url_contenido.tipo === 1)
    {
      renderCustomComponent(Image, {src:contenido},true)

    }else if (url_contenido.url && url_contenido.tipo === 0) {

      addLinkSnippet({
        title: 'Link',
        link: contenido,
        target: '_blank'
      });

    }else if (item.tipo_usuario==="bot" || item.tipo_usuario==="watson" || item.tipo_usuario==="chatcenter" ){

        addResponseMessage(contenido);
        //renderCustomComponent(Texto, {contenido:contenido},true)


    }else if(item.tipo_usuario==="usuario" && usuario)
    {
      addUserMessage(contenido);
    }

    analizarMenu(item);

  }


  /*
  handleQuickButtonClicked
  const buttonList = [{
     label: 'Button1',
     value: 'button1'
 },
 {
     label: 'Button2',
     value: 'button2'
 },
 {
     label: 'Button3',
     value: 'button3'
 }];
 setQuickButtons(buttonList)
  */

  function sendMessage(uid,fecha,contenido){
      setQuickButtons([]);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({"msg":[{"id":fecha,"messaging":[
      {"message":{"type":"text","text":contenido},"user":{"id":uid}}],"date":fecha}]});

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://bots.automatadg.com/webchat/webhook?bot="+props.automataBot+"&id="+props.automataEmpresa, requestOptions)
      .then(response => response.text())
      .then(result => setResultado(result))
      .catch(error => setResultado(error));
  }

  const  handleQuickButtonClicked = data => {
      //console.log(data);
      addUserMessage(data);
      sendMessage(usuario.uid,Date.now(),data);
    };

  const  handleNewUserMessage = (newMessage) => {
      sendMessage(usuario.uid,Date.now(),newMessage);
    //  renderCustomComponent(Iniciar, {})
    };

  return(
     <React.Fragment>
         <div style={{'--color-fondo':props.automataFondo == null|| props.automataFondo ==''? colores.fondo : props.automataFondo,
                      '--color-texto': props.automataTexto == null|| props.automataTexto ==''? colores.texto : props.automataTexto }}>
             <Widget
                 handleNewUserMessage={handleNewUserMessage}
                 handleQuickButtonClicked={handleQuickButtonClicked}
                 profileAvatar={props.automataAvatar}
                 title={nombreBot}
                 launcherOpenImg={props.automataAvatarBoton == null || props.automataAvatarBoton == '' ? null : props.automataAvatarBoton}
                 senderPlaceHolder={"Escribe un mensaje..."}
                 showTimeStamp={false}
                 emojis={true}
                 fullScreenMode={false}
                 subtitle={(
                     <IconButton aria-label="exit" onClick={() => auth.signOut()}>
                         <ExitToAppIcon />
                     </IconButton>
                 )}
             />
         </div>
     </React.Fragment>
  )

}


function isUrl(s) {


    var regexp = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/

    if(regexp.test(s)){ //si es url

      let url=regexp.exec(s);
      if(url===null)
      {
        //console.log( {url:false,tipo:""});
        return {url:false,tipo:""};
      }

      if(url[0].length!==s.length)
      {
        //console.log( {url:false,tipo:""});
        return {url:false,tipo:""};
      }

      let arreglo = s.split("?");
      let indice= arreglo[0].lastIndexOf(".");
      let nombre= arreglo[0].split("/");
      let nombre_par=nombre[nombre.length-1].split('%2F');


      if(nombre_par.length>0)
      {
        nombre=nombre_par;
      }

      let tipo= arreglo[0].substring(indice+1,arreglo[0].length).toLowerCase();

      let isimagen =ex_imagen.indexOf(tipo);
      if(isimagen!==-1)
      {
          return  {url:true,tipo:1,nombre:nombre[nombre.length-1]};//imagen
      }

      return  {url:true,tipo:0,nombre:""};//archivo

    }else{
      return {url:false,tipo:""};
    }
}



export default Chat;
