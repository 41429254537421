import React, { Component } from "react";
import { signin, signInWithGoogle, signInWithGitHub,signInAnonymously,signInWithFacebook } from "../helpers/auth";
//----------------------------------------------
import { withStyles  } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import FormLabel from '@material-ui/core/FormLabel';
import EmailIcon from '@material-ui/icons/Email';
import FacebookIcon from '@material-ui/icons/Facebook';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import { auth } from "../services/firebase";

const useStyles = theme => ({
  root: {
    minWidth: 275,
   },
   list: {
    width: '100%',
    color: "black",
  },
   button: {
      margin: theme.spacing(1),
  },
  });
//---------------inicio de metodos------------------
class Login extends Component {

  constructor(props) {
    super(props);
    //console.log('id: ',props.id)
    this.state = {
      error: null,
      email: "",
      password: "",
      bot: this.props.bot,
      id: this.props.id,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.googleSignIn = this.googleSignIn.bind(this);
    this.githubSignIn = this.githubSignIn.bind(this);
    this.facebookSignIn = this.facebookSignIn.bind(this);
    this.AnonymouslySignIn= this.AnonymouslySignIn.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ error: "" });
    try {
      await signin(this.state.email, this.state.password);
    } catch (error) {
      this.setState({ error: error.message });
    }
  }

  async googleSignIn() {
    try {
      await signInWithGoogle();
      console.log("liogin google",auth.currentUser);
      this.setState({bot:this.props.bot})
    } catch (error) {
      this.setState({ error: error.message });
        console.log("error login google",error.message);
    }
  }

  async githubSignIn() {
    try {
      await signInWithGitHub();
    } catch (error) {
      this.setState({ error: error.message });
    }
  }
  async facebookSignIn() {
    try {
      await signInWithFacebook();
    } catch (error) {
      // console.log('errro facebook', error);
      this.setState({ error: error.message });
    }
  }
  async AnonymouslySignIn() {
    try {
      await signInAnonymously();
    } catch (error) {
      console.log(error)
      this.setState({ error: error.message });
    }
  }


  render() {
    const { classes } = this.props;
    return (
      <div className="container" style={{ marginTop: '20px' }}>

                <FormLabel component="legend" >Ingresa usando una de las opciones: </FormLabel>
                <List className={classes.list} aria-label="mailbox folders">
                  <ListItem button onClick={this.googleSignIn}>
                    <ListItemAvatar>
                      <Avatar>
                        <EmailIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Google" />
                  </ListItem>
                  <Divider />
                  <ListItem button divider onClick={this.facebookSignIn}>
                    <ListItemAvatar>
                      <Avatar>
                        <FacebookIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Facebook" />
                  </ListItem>
                  <ListItem button onClick={this.AnonymouslySignIn}>
                    <ListItemAvatar>
                      <Avatar>
                        <AccountBoxIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Anónimo" />
                  </ListItem>
                </List>

      </div>
    );
  }
}

export default withStyles(useStyles)(Login);
