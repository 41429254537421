import { authProveder } from "../services/firebase";

export function signup(email, password) {
  return authProveder().createUserWithEmailAndPassword(email, password);
}

export function signin(email, password) {
  return authProveder().signInWithEmailAndPassword(email, password);
}

export function signInWithGoogle() {
  const provider = new authProveder.GoogleAuthProvider();
  return authProveder().signInWithPopup(provider);
}

export function signInWithGitHub() {
  const provider = new authProveder.GithubAuthProvider();
  return authProveder().signInWithPopup(provider);
}
export function signInWithFacebook() {
  const provider = new authProveder.FacebookAuthProvider();
  return authProveder().signInWithPopup(provider);
}

export function signInAnonymously() {
  return authProveder().signInAnonymously();
}

export function logout() {
  return authProveder().signOut();
}
