/**
* @fileoverview Appjs
* @version  2.0Beta
* @author Hugo Chicoj<hugocc@automtadg.com>
* @copyright  Automtadg.com
*
* History
* v2.0Beta – Se pasaron los props del componente padre
*
* v1.0Beta Fue desarrollada por Dina Antuche <madian@automtadg.com>
*/

import './App.css';
import Chat from './componentes/chat'
import React,{useEffect} from 'react';
import { db} from "./services/firebase";
import Alert from '@material-ui/lab/Alert';

function App(props) {

  const [parametrosCorrectos,setParametrosCorrectos] = React.useState(false);

  useEffect(() =>{
      db.ref('/bots/'+props.automataEmpresa+'/'+props.automataBot).once('value').then(function(snapshot){

          if(snapshot.val()!==null){
            setParametrosCorrectos(true);
          }
      });

     return ()=>{
     }
  },[]);


  return (
    parametrosCorrectos?
      <Chat {...props} />
      :
      null
  );
}

export default App;
